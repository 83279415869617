<template>
    <div class="section" key="tatvorwurf_erweitert">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3>Jeder zweite verschickte Bußgeldbescheid ist fehlerhaft! Ist es Ihrer auch?</h3>
            <p>Jetzt <a href="#">kostenlos</a> und <a href="#">unverbindlich</a> prüfen</p>
        </div>
        <div class="form-container">
            <p class="form-question">Bitte wählen Sie jetzt genau</p>
            <div class="form-card">
            <input type="radio" v-model="user.accusationExtended" name="alcohol-radio" value="Alkohol - / Drogenverstoß" id="question-2-1">
            <label for="question-2-1" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons-beer.svg"></span><span>Alkohol - / Drogenverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusationExtended" name="mobile-radio" value="Handyverstoß" id="question-2-2">
            <label for="question-2-2" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-phonelink-ring.svg"></span><span>Handyverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusationExtended" name="parking-radio" value="Parkverstoß" id="question-2-3">
            <label for="question-2-3" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-parking.svg"></span><span>Parkverstoß</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.accusationExtended" name="other-radio" value="Sonstiges" id="question-2-4">
            <label for="question-2-4" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-no-entry.svg"></span><span>Sonstiges</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AccusationExtended',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>